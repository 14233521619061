import React, { useState, useEffect, useRef } from 'react';
import './Windows.css';
import Login from './pages/Login';
import wb from "./wb.png";
import logo from "./catpng.png";

function Windows() {
    const [open, setOpen] = useState(true); // Controls terminal visibility
    const [input, setInput] = useState('');
    const [output, setOutput] = useState([]); // Stores the terminal output
    const [isTyping, setIsTyping] = useState(false); // Controls typing animation
    const [profitChance, setProfitChance] = useState(92); // Controls profit chance variation
    const inputRef = useRef(null);
    const terminalRef = useRef(null); // Ref to detect clicks on terminal

    const [apiData, setApiData] = useState(null); // API data

    // Typing animation function
    const typeMessage = (message, callback) => {
        setIsTyping(true);
        let index = 0;
        let newMessage = '';

        const interval = setInterval(() => {
            newMessage += message[index];
            index++;

            setOutput((prev) => [...prev.slice(0, -1), newMessage]); // Ensure the last message is updated

            if (index >= message.length) {
                clearInterval(interval);
                setIsTyping(false);
                if (callback) callback(); // Call the callback once typing is complete
            }
        }, 50); // Speed of the typing animation
    };

    // Fetch data from API when terminal is opened
    useEffect(() => {
        if (open && !apiData) {
            fetch('https://apitoreturnca.onrender.com/api/purchaseData')
                .then((response) => response.json())
                .then((data) => {
                    setApiData(data);
                    displayMenu(data.tokenName); // Pass the token name to display in the menu
                })
                .catch((error) => console.error('Error fetching data from API:', error));
        }
    }, [open, apiData]);

    // Profit chance calculation with random oscillation
    useEffect(() => {
        const interval = setInterval(() => {
            setProfitChance((prev) => Math.floor(Math.random() * (100 - 92 + 1)) + 92);
        }, 800); // Updates every 800ms
        return () => clearInterval(interval); // Clean up interval on unmount
    }, []);

    // Handle input commands
    const handleCommand = (e) => {
        if (e.key === 'Enter' && !isTyping) {
            const choice = input.trim().toLowerCase();
            let response = '';

            setOutput(prev => [...prev, `> ${input}`]); // Display the command

            if (choice === 'menu') {
                displayMenu(apiData.tokenName);
                setInput('');
                return;
            }

            switch (choice) {
                case '1':
                    response = `${apiData.tokenCA} - Go to pump or copy`;
                    typeMessage(response, () => {
                        setOutput(prev => [
                            ...prev,
                            <div key={prev.length}>
                                {apiData.tokenCA} - Go to{' '}
                                <a href={apiData.link} target="_blank" rel="noopener noreferrer">
                                    pump
                                </a>{' '}
                                or{' '}
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigator.clipboard.writeText(apiData.tokenCA);
                                        typeMessage('Token copied to clipboard!');
                                    }}
                                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                >
                                    copy
                                </a>
                                <br /> {/* Adiciona a quebra de linha aqui */}
                            </div>
                        ]);
                    });
                    break;
                case '2':
                    response = `Token profit chance: ${profitChance}%`;
                    typeMessage(response);
                    break;
                case '3':
                    response = `Twitter: ${apiData.twitterLink}\nTelegram: ${apiData.telegramLink}\nPumpFun: ${apiData.link}`;
                    typeMessage(response.replace(/\n/g, '<br />')); // Converte quebras de linha para <br />
                    break;
                case '4':
                    response = 'Closing terminal...';
                    typeMessage(response, () => {
                        setOpen(false);
                    });
                    break;
                default:
                    response = 'Invalid command, please choose a number from the menu.';
                    typeMessage(response);
            }

            setInput(''); // Clear the input after processing the command
        }
    };

    // Display the menu
    const displayMenu = (tokenName) => {
        const menuText = `Hi, I'm ${tokenName}, this is my terminal. What do you want to know?\n1. Token CA\n2. Token chance profit\n3. Social Media\n4. End terminal`;
        setOutput((prev) => [...prev, '']); // Add an empty placeholder for the typing animation
        typeMessage(menuText); // Call the typing animation function to show the menu
    };

    // Focus input when clicking anywhere in the terminal
    const handleTerminalClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    return (
        <div className="windows">
            {open && (
                <div ref={terminalRef} className="pageTerminal" onClick={handleTerminalClick}>
                    <div className="head">
                        <div className="nameaba">Dogedinho</div>
                        <div className="abas">
                            <div className="icon-x">-</div>
                            <div className="icon-x">□</div>
                            <div onClick={() => setOpen(false)} className="icon-x">x</div>
                        </div>
                    </div>
                    <div className="code">
                        {output.map((line, index) => (
                            <span key={index} className="message" dangerouslySetInnerHTML={{ __html: line }}></span> // Usando dangerouslySetInnerHTML para que as quebras de linha sejam interpretadas
                        ))}
                        {isTyping ? <div>Typing...</div> : null}
                        <input
                            type="text"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyDown={handleCommand}
                            className="input"
                            ref={inputRef}
                            autoFocus
                        />
                    </div>
                </div>
            )}
            <Login />
            <div className="line"></div>
            <div className="bt"></div>
            <div className="trueBt">
                <img src={wb} alt="" />
                <img onClick={() => setOpen(true)} className='logopng' src={logo} alt="" />
            </div>
        </div>
    );
}

export default Windows;
