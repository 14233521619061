// C:\SOLANA PROJETOS\PROJETO INSANO\app\src\windows\pages\Login.jsx

import React, { useRef } from 'react';
import "./Login.css"
import logo from "./catterminal.png"

function Login() {

    const loginRef = useRef();
    const animate = useRef();
    const loginPage = useRef();

    function loginF() {
        if (loginRef.current && animate.current && loginPage.current) {
            loginRef.current.style.display = "none";
            animate.current.style.display = "flex";
            
            // Mudar loginPage para display: flex
            loginPage.current.style.display = "flex";

            // Manter por 2 segundos e depois ocultar
            setTimeout(() => {
                loginPage.current.style.display = "none";
            }, 2000); // 2000 milissegundos = 2 segundos
        }
    }

    return (
        <div ref={loginPage} className="Login">
            <div onClick={loginF} ref={loginRef} className="user">
                <div className="icon">
                    <img src={logo} alt="" />
                </div>
                <div className="name">Dogedinho</div>
            </div>
            <div ref={animate} className="load">
                <div className="name">Logging</div>
                <div className="animation"/>
            </div>
        </div>
    );
}

export default Login;
